import React, { useState } from "react";
import "../Func/Func.css";
import funcIcon1 from "../../images/func1.svg";
import funcIcon2 from "../../images/func2.svg";
import funcIcon3 from "../../images/func3.svg";

function Func(props) {
  return (
    <section className="func">
      <div className="container">
        <div className="func__inner">
          <h2 className="func__title">Функциональность продукта</h2>
          <div className="func__cards">
            <div className="func__card">
              <img src={funcIcon1} alt="icon" className="func__card-icon" />
              <h3 className="func__card-title">Транскрибация речи</h3>
              <p className="func__card-subtitle">
                Получайте точные текстовые расшифровки из аудио и видео
                контента, упрощая анализ и изучение материалов.
              </p>
            </div>

            <div className="func__card">
              <img src={funcIcon2} alt="icon" className="func__card-icon" />
              <h3 className="func__card-title">Диаризация речи</h3>
              <p className="func__card-subtitle">
                Определяйте отдельных говорящих, легко различая участников
                разговора и разбивая их фразы на тайм-коды для быстрой
                навигации.
              </p>
            </div>

            <div className="func__card">
              <img src={funcIcon3} alt="icon" className="func__card-icon" />
              <h3 className="func__card-title">Редактирование материалов</h3>
              <p className="func__card-subtitle">
                Редактируйте контент, используя возможности точечной настройки
                под ваши нужды, делая его более привлекательным и
                профессиональным.
              </p>
            </div>
          </div>
          <div className="func__btm">
            <p className="func__btm-left">
              Стоимость услуги по предоставлению программного обеспечения
              рассчитывается индивидуально на основе ваших потребностей
            </p>
            <div className="func__btm-right">
              <div className="func__btm-right-box">
                Для расчета стоимости отправьте запрос на почту:
                <a
                  href="mailto:info@palatine.ru"
                  className="func__btm-right-box-email"
                >
                  info@palatine.ru
                </a>
              </div>
              <div className="func__btm-right-box">
                Для связи с поддержкой напишите на почту:
                <a
                  href="mailto:support@palatine.ru"
                  className="func__btm-right-box-email"
                >
                  support@palatine.ru
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Func;
