import { useState, useEffect } from "react";

const useInputValidation = (initialValue, validations) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isBtnClick, setIsBtnClick] = useState(validations.isBtnClick || false);

  useEffect(() => {
    // If user has interacted with input (dirty) or button is clicked, we run validation
    if (isDirty || isBtnClick) {
      validate();
    }
  }, [inputValue, isDirty, isBtnClick]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    setIsDirty(true);
  };

  const handleOnClick = () => {
    setIsBtnClick(true);
    validate();
  };

  const validate = () => {
    let message = "";

    if (validations.isEmpty && !inputValue) {
      message = "Поле не может быть пустым.";
    } else if (
      validations.minLength &&
      inputValue.length < validations.minLength
    ) {
      message = `Минимальная длина ключа должна быть ${validations.minLength} символов.`;
    }

    setValidationMessage(message);
  };

  return {
    inputValue,
    handleInputChange,
    handleInputBlur,
    handleOnClick,
    validationMessage,
    isDirty,
  };
};

export default useInputValidation;
