import React from "react";
import "./Header.css";
import headerLogoFirst from "../../images/header-logo-first.svg";
import headerLogoSecond from "../../images/header-logo-second.png";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <div className="header__left">
            <img
              src={headerLogoFirst}
              alt="Logo media class"
              className="header__left-logo-first"
            />
            <div className="header__left-divider"></div>
            <a
              href="https://palatine.ru"
              target="_blank"
              className="header__left-logo-second-link"
            >
              <img
                src={headerLogoSecond}
                alt="Logo Palatine Vision"
                className="header__left-logo-second"
              />
            </a>
          </div>
          <div className="header__right">
            По вопросам сотрудничества: <br />
            <a
              href="mailto:info@palatine.ru"
              target="_blank"
              className="header__email"
            >
              info@palatine.ru
            </a>
          </div>
        </div>
      </div>
    </header>

    // <header className={isSrolled ? 'header scrolled' : 'header'}>
    //     <div className='header__section'>
    //         <a className='header__logo-link' href='https://mediaclass.digis.ru/' target='_blanc'>
    //             <img className='header__logo' src={logoHeader} alt="Логотип" />
    //         </a>
    //         <LogoGroup />
    //     </div>
    // </header>
  );
}

export default Header;
