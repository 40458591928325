import React, { useRef, useEffect } from "react";
import "./InputWithMask.css";

function InputWithMask(props) {
  const inputRef = useRef(null);

  // Функция для автоматического форматирования значения (добавление дефисов каждые 6 символов)
  const formatValue = (value) => {
    // Преобразуем буквы в верхний регистр и удаляем все символы, кроме букв и цифр (a-z, A-Z, 0-9)
    const rawValue = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");

    // Разбиваем строку по 6 символов и добавляем дефисы
    const formattedValue = rawValue.match(/.{1,6}/g)?.join("-") || "";

    return formattedValue;
  };

  const handleChange = (evt) => {
    const rawValue = evt.target.value;
    const cursorPosition = inputRef.current.selectionStart;
    const previousValue = props.value;

    // Форматируем введённое значение (преобразуем в верхний регистр)
    const formattedValue = formatValue(rawValue);

    // Рассчитываем и устанавливаем правильную позицию курсора
    const newCursorPosition = calculateCursorPosition(
      cursorPosition,
      previousValue,
      formattedValue
    );

    // Передаём отформатированное значение в родительский компонент
    props.onChange({
      target: {
        name: props.name,
        value: formattedValue,
      },
    });

    // Устанавливаем правильную позицию курсора
    requestAnimationFrame(() => {
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    });
  };

  // Функция для расчета позиции курсора с учетом изменений (добавление/удаление дефисов)
  const calculateCursorPosition = (cursorPosition, oldValue, newValue) => {
    let unformattedOldValue = oldValue.replace(/-/g, "");
    let unformattedNewValue = newValue.replace(/-/g, "");

    // Определяем изменения в длине строки без дефисов
    let diff = unformattedNewValue.length - unformattedOldValue.length;

    // Перемещаем курсор вправо, если добавлены дефисы
    if (diff > 0 && newValue[cursorPosition - 1] === "-") {
      cursorPosition++;
    }

    // Если курсор оказывается на дефисе, перемещаем его за дефис
    if (newValue[cursorPosition] === "-") {
      cursorPosition++;
    }

    return cursorPosition;
  };

  const handlePaste = (evt) => {
    const pastedText = evt.clipboardData.getData("text");
    const currentPosition = inputRef.current.selectionStart;
    const newValue =
      props.value.substring(0, currentPosition) +
      pastedText +
      props.value.substring(inputRef.current.selectionEnd);

    // Форматируем вставленный текст
    const formattedValue = formatValue(newValue);

    props.onChange({
      target: {
        name: props.name,
        value: formattedValue,
      },
    });

    const endPosition = formattedValue.length;
    inputRef.current.setSelectionRange(endPosition, endPosition);
    evt.preventDefault();
  };

  const handleKeyDown = (evt) => {
    // Если нажато Ctrl+A
    if ((evt.metaKey || evt.ctrlKey) && evt.key === "a") {
      evt.preventDefault();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  };

  return (
    <>
      <input
        className="download-screen__input main__box-input"
        type="text"
        name="license_key"
        id="licenseKey"
        placeholder="XXXXXX-XXXXXX-XXXXXX-XXXXXX"
        value={props.value}
        maxLength={27}
        required
        onChange={handleChange} // Обработка изменений с форматированием и преобразованием в верхний регистр
        onKeyDown={handleKeyDown}
        onPaste={handlePaste} // Обработка вставки с форматированием
        ref={inputRef}
      />
    </>
  );
}

export default InputWithMask;
